.MuiDialogTitle-root {
  text-align: center;
}
.dialog_close_btn{
  padding: 0.5rem;
  button{
    float: right;
    background: none;
    border: none;
  }
}

.dialog_button_main {
  button {
    width: 50%;
    background-color: white;
    padding: 0.6rem 0;
    border: 1px solid var(--border-secondary);

    &:first-child {
      color: var(--home-color);

      &:hover {
        opacity: 1 !important;
        color: white;
        background-color: var(--home-color);
      }
    }

    &:last-child {
      color: red;

      &:hover {
        opacity: 1 !important;
        color: white;
        background-color: red;
      }
    }
  }
}

.create_project_dialog_main, .assign_worker_dialog_main, .change_member_authority_dialog_main, .add_member_dialog_main, .work_modify_dialog_main, .work_add_dialog_main {
  .dialog_content {
    width: 350px;
    padding: 1rem;

    input, select {
      padding: 1rem 0.3rem;
      border: 1px solid var(--border-secondary);
      width: 100%;
    }
  }
}

.worker_info_dialog_main {
  .close_btn_main {
    padding: 0 1rem;
    margin-bottom: 1rem;

    button {
      width: 100%;
      padding: 0.5rem 0;
      background-color: var(--home-color);
      color: white;
      border: none;
      border-radius: 5px;
    }
  }
}

.assign_worker_dialog_main {
  .assign_worker_form {
    select {
      margin-top: 1rem;
    }
  }
}


.work_add_dialog_main {
  .dialog_content {
    width: 100vw !important;
    max-width: 600px;

    .work_form {
      margin-bottom: 1rem;
    }
  }
}

.work_modify_dialog_main {
  .work_form {
    margin-bottom: 1rem;

    label {
      font-size: 0.8rem;
      color: gray;
    }
  }
}

.create_project_dialog_main {
  .project_status_list {
    span {
      cursor: pointer;
      padding: 0.6rem 1rem;
      font-size: 0.875rem;
      border: 1px solid var(--border-secondary);
      border-radius: 30px;
      background-color: white;

      &:hover, &.active {
        background-color: var(--home-color);
        color: white;
      }
    }
  }
}

.change_work_status_dialog_main {
  .dialog_content {
    max-width: 800px;
    padding: 1rem;
    width: 100vw;

  }


  .work_status_list {
    padding: 1rem;

    span {
      cursor: pointer;
      padding: 0.6rem 1rem;
      font-size: 0.875rem;
      border: 1px solid var(--border-secondary);
      border-radius: 30px;
      background-color: white;

      &:hover, &.active {
        background-color: var(--home-color);
        color: white;
      }
    }
  }

  .work_status_change_form_main {
    padding: 1rem;

    .reason_input {
      width: 100%;
      height: 50px;
      border: 1px solid var(--border-secondary);
      border-radius: 5px;
      padding: 0 8px
    }
  }

  .work_status_image_uploader_main {
    padding: 1rem;

    .upload_image_form {
      .upload_img_list {
        padding: 1rem 2rem;
        border: 1px solid var(--border-secondary);
        min-height: 200px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .image_item_btn_main {
          margin-top: 1rem;

          button {
            background-color: white;
            border-radius: 5px;

            &:first-child {
              margin-right: 0.5rem;
              color: var(--home-color);
              border: 1px solid var(--home-color);
            }

            &:last-child {
              margin-left: 0.5rem;
              color: red;
              border: 1px solid red;
            }
          }
        }
      }

      .add_btn {
        margin-top: 2rem;
        width: 100%;
        padding: 0.5rem 0;
        border-radius: 5px;
        background-color: var(--home-color);
        color: white;
        border: none;
      }
    }
  }
}


.change_member_authority_dialog_main {
  .delete_member_btn {
    text-align: right;
    margin-bottom: 0.5rem;
    width: 100%;

    button {
      width: 100%;
      border-radius: 5px;
      color: red;
      border: 1px solid red;
      background-color: white;
      padding: 0.34rem;
    }
  }
}


.add_member_dialog_main {
  .member_authority_list {
    padding: 0.5rem 0;

    span {
      cursor: pointer;
      padding: 0.6rem;
      font-size: 0.875rem;

      &:hover, &.active {
        background-color: var(--home-color);
        color: white;
      }
    }
  }
}
