.login_main {
  overflow: scroll;
  position: relative;
  height: 100vh;
  background: linear-gradient(45deg, #d0e4f7, #73b1e7 24%, #0a77d5 50%, #539fe1 79%, #87bcea);
  display: flex;
  align-items: center;
  justify-content: center;

  .login_box {
    width: 400px;
    background-color: #fff;
    border-radius: 20px;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //z-index: 999;
    padding: 3rem 2rem;
    margin: 4rem 0;

    #box_title {
      text-align: center;
      font-size: 1.8rem;
      padding: 2rem 0;
    }

    .form_group {
      margin-bottom: 1rem;
    }

    .forget_pwd_wrapper {
      padding: 0.5rem 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      a {
        margin-bottom: 0.5rem;

        span {
          color: gray;
        }
      }
    }

    .form_btn_wrapper {
      padding-top: 1.5rem;

      button {
        border-radius: var(--button-radius);
        width: 100%;
        padding: 0.8rem 0;
        background-color: var(--home-color);
        border: none;
        color: white;

        &:disabled {
          background-color: rgba(128, 128, 128, 0.32);
        }

        svg{
          color: white;
        }
      }
    }
  }
}

.find_password_main {
  small {
    color: gray;
  }

  input {
    margin: 1rem 0;
  }

  .reset_pwd_btn {
    width: 100%;
    padding: 0.7rem 0;
    border: none;
    background-color: var(--home-color);
    color: white;
    border-radius: 5px;

    &:disabled {
      background-color: gray;
      opacity: 0.5;
    }
  }
}