.medicine_wrapper {
  background-color: #F2F2F7;

  &.hidden {
    display: none;
  }

  .page_title_wrapper {
    padding: 1.2rem 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;

    #page_title {
      font-size: 1.3rem;
      margin: 0;
    }

    .add_medicine {
      padding: 0.3rem 0.7rem;
      border: 1px solid #3886ff;
      color: #3886ff;
      background: none;
      font-size: 0.875rem;
    }

    .down_excel{
      margin-left: 0.5rem;
      padding: 0.3rem 0.7rem;
      border: 1px solid green;
      color: green;
      background: none;
      font-size: 0.875rem;
    }
  }
}

.medicine_list {
  padding: 1.5rem 0.8rem;
  overflow-y: scroll;
  .medicine_item {
    background-color: #FFF;
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
    border: 1px solid var(--border-secondary);
    border-radius: 8px;
    width: 100%;
    padding: 0.7rem 0.5rem;
    cursor: pointer;

    .icon_img {
      width: 35px;
      height: 35px;
    }

    .medicine_info {
      flex: 1;
      padding: 0 0.5rem;

      h4 {
        margin: 0;
        font-size: 0.875rem;
      }
    }

    .medicine_use {
      width: 70px;
      display: flex;
      flex-direction: column;

      p {
        text-align: center;
        margin: 0;
        font-size: 0.75rem;
        color: #3c3b3b;
      }
    }
  }
}


.back_btn {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;

  svg {
    width: 40px;
    height: 40px;
    color: #3886ff;
  }
}

.add_medicine_wrapper {
  padding: 1rem 0.8rem;
}

.form_wrap {
  margin-bottom: 1rem;

  label {
    font-weight: bold;
    width: 100%;
  }

  .form_search {
    padding-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
  }

  input {
    flex: 1;
    border-radius: 8px;
    border: 1px solid #EEE;
    padding: 0.75rem;

  }

  button {
    width: 80px;
    color: #3886ff;
    border: 1px solid #3886ff;
    padding: 0.75rem 0;
    border-radius: 8px;
    background: none;
  }
}

.dialog_bottom_btn {
  width: 100%;
  padding: 0 0.8rem;

  button {
    background-color: #3886ff;
    width: 100%;
    color: #FFF;
    padding: 0.75rem 0;
    border: none;
    border-radius: 8px;
  }
}

.select_medicine_btn {
  width: 70px;
  padding: 0.75rem 0;
  background: none;
  border: 1px solid #3886ff;
  color: #3886ff;
  border-radius: 8px;
}

.pagination_wrapper {
  padding: 1rem;
  display: flex;
  justify-content: center;
}


.medicine_info_list{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  li{
    justify-content: flex-start;
    font-size: 0.7rem;
  }
}

.no_scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.no_scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}