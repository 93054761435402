.register_auth_phone_main {
  .auth_phone_form_wrapper, .auth_num_form_wrapper {
    margin: 0 0 10px 0 !important;

    button {
      border: none;
      width: 90%;
      height: 100%;
      background: #4397fd;
      color: white;

      &:hover {
        opacity: 0.5;
      }
    }

  }

  .auth_num_form_wrapper {
    transition: height 1s;
    height: 0;
    visibility: hidden;

    &.show {
      height: 46px;
      visibility: visible;

    }
  }
}


.register_user_type {

  .user_type_list_main {
    display: flex;
    flex-direction: column;

    .user_type_item {
      padding: 0.6rem 0.7rem;
      border: 1px solid var(--border-secondary);
      border-radius: 15px;
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &.select {
        border-color: var(--home-color);
      }

      .type_info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        small {
          color: gray;
        }

        span {
          margin: 0 0.3rem;
          font-weight: bold;
        }
      }
    }
  }

  .account_info_main {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;

    .account_info_item {
      margin-top: 0.5rem;
      padding: 0.5rem 0.6rem;
      border-radius: 15px;
      border: 1px solid var(--border-secondary);
      background-color: var(--border-secondary);

      span {
        color: gray;
      }
    }
  }
}