.map_console_main {
  width: 100%;
  display: flex;
  position: relative;

  .screen_loader_main {
    z-index: 999999;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .loader_box {
      background-color: white;
      border-radius: 15px;
      width: 150px;
      height: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .left_side_bar_main {
    z-index: 99;
    background-color: white;
    width: 63px;
    border-right: 1px solid var(--border-secondary);
    position: relative;

    .nav_logo_main {
      cursor: pointer;
      text-align: center;
      padding: 10px 0;
      border-bottom: 1px solid var(--border-secondary);
      color: var(--home-color);
    }

    .logout_btn_main {
      position: absolute;
      bottom: 30px;

      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        font-size: 11px;
        border: none;

        &:hover {
          color: var(--home-color);
        }
      }
    }


    .nav_bar_item_list {

      ul {
        li {
          cursor: pointer;
          padding: 13px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          color: #333;

          &:hover {
            color: var(--home-color);
          }

          &.select {
            color: white;
            background-color: var(--home-color);
          }
        }
      }
    }
  }
}

.side_bar_container {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;

  .left_side_space_main {
    width: 400px;
  }
}

.space_base_main {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 2rem 15px 0 15px;
  position: relative;
  transition: 0.5s ease;

  &.collapse_side_bar {
    //width: 0;
    transform: translateX(-100%);
    padding: 0;
    z-index: 10;

    .space_base_content, #space_base_title {
      display: none;
    }

    .collapse_btn_main {
      button {
        transform: rotate(180deg);

        svg {

        }
      }
    }
  }

  .collapse_btn_main {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    z-index: 10;
    background: white;

    button {
      overflow: hidden;
      display: inline-block;
      width: 22px;
      height: 49px;
      color: gray;
      vertical-align: top;
      border: 1px solid var(--border-secondary);
      background: white;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.project_create_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button {
    padding: 0.4rem 0.6rem;
    background-color: white;
    color: var(--home-color);
    border: 1px solid var(--home-color);
    border-radius: 5px;
  }
}

.project_list_main {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;

  .project_item {
    margin-bottom: 0.6rem;
    border: 1px solid var(--border-secondary);
    width: 100%;
    padding: 0.4rem 0.3rem;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .project_sub_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    small {
      color: gray;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.MuiDrawer-paper {
  overflow-y: unset !important;
}

.side_bar_content {
  position: relative;
  width: 350px;
  padding: 2rem 15px 0 15px;

  .close_side_bar_btn {
    position: absolute;
    left: -43px;
    top: 75px;
    width: 43px;
    height: 44px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-left: 0;
    border-radius: 4px 0 0 4px;
    font-size: 1px;
    line-height: 1px;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    z-index: 999;

    svg {
      color: gray;
    }
  }
}

#work_status_info {
  font-weight: bold;
  border-radius: 20px;
  padding: 0.4rem 0.5rem;
  border: 1px solid var(--border-secondary);
}

.right_polygon_search_side_bar {

  .side_bar_header {
    margin-top: 2rem;
  }

  .add_work_btn_main {
    button {
      width: 100%;
      padding: 0.8rem 0;
      background-color: var(--home-color);
      color: white;
      border: none;
    }
  }
}

.right_polygon_detail_side_bar {
  overflow-y: scroll;
}

.right_polygon_detail_side_bar, .right_polygon_search_side_bar {

  .side_bar_info {
    .polygon_info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;

      span {
        &:first-child {
          color: gray;
        }

        &:last-child {
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
    }

    .delete_work_btn_main {
      margin-top: 0.5rem;

      button {
        width: 100%;
        border: 1px solid red;
        padding: 0.6rem 0;
        border-radius: 5px;
        color: white;
        background-color: red;
      }
    }

    .side_bar_button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 2rem;

      button {
        width: 50%;
        border: 1px solid var(--border-secondary);
        padding: 0.6rem 0;
        border-radius: 5px;

        &:first-child {
          background-color: var(--home-color);
          color: white;
        }

        &:last-child {
          background-color: gray;
          color: white;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.map_loader_wrapper, {
  background-color: #00000054;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

