.mypage_main {
  padding-top: 4rem;

  .form_group {
    padding: 1rem 0;

    input {
      border-radius: 5px;
      border: 1px solid var(--border-secondary) !important;
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
      padding: 0.7rem 0;
      background-color: white;
      color: var(--home-color);
      border: 1px solid var(--home-color);
      border-radius: 5px;

      &:disabled {
        background-color: gray;
        opacity: 0.5;
        color: white;
        border-color: gray;
      }
    }
  }
}