.project_detail_main {

  .detail_map_box_main {
    position: absolute;
    left: calc(100% + 40px);
    bottom: 30px;
    background-color: white;
    border-radius: 5px;
    width: 500px;
    padding: 1rem;

    .work_status_color_list, .work_mode_worker_color_list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .color_worker_item {
        margin-right: 1rem;
        padding: 0.3rem;

        &.active {
          background-color: var(--home-color);
          color: white;
          border-radius: 5px;
        }
      }

      #status_color_circle {
        margin-right: 0.3rem;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    .map_box_btn_main {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      button {
        width: 47%;
        padding: 0.5rem 0;
        background-color: var(--home-color);
        border: 1px solid var(--home-color);
        color: white;
        border-radius: 5px;

        &.active {
          background-color: red !important;
          border-color: red;
        }
      }
    }

    .assign_btn_main {
      margin-top: 1rem;

      button {
        width: 100%;
        padding: 0.5rem 0;
        background-color: var(--home-color);
        border: 1px solid var(--home-color);
        color: white;
        border-radius: 5px;

        &.active {
          background-color: red !important;
          border-color: red;
        }
      }
    }
  }


  .work_filter_main {
    padding: 1rem;
    background: white;
    position: absolute;
    top: 20px;
    left: calc(100% + 80px);
    width: 500px;
    height: auto;
    border-radius: 5px;

    .work_mode_filter_input {
      display: flex;
      flex-direction: row;
      border: 1px solid var(--border-secondary);
      border-radius: 5px;
      padding: 0.2rem 0.2rem;
      margin: 0.8rem 0.5rem 1rem 0.5rem;

      select {
        width: 30%;
        padding: 0 0.3rem;
        border: none;
        background-color: white;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%);

        background-position: calc(100% - 20px) calc(0.5em + 2px),
        calc(100% - 15px) calc(0.5em + 2px);
        background-size: 5px 5px,
        5px 5px;
        background-repeat: no-repeat;
      }

      input {
        padding: 0.5rem 0.2rem;
        width: 70%;
        border: none;
      }
    }


    .work_status_list {
      padding: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      span {
        margin: 0 1rem 1rem 0;
        cursor: pointer;
        padding: 0.4rem 0.7rem;
        font-size: 0.875rem;
        border: 1px solid var(--border-secondary);
        border-radius: 30px;
        background-color: white;

        &:hover, &.active {
          background-color: var(--home-color);
          color: white;
        }
      }
    }

    .work_filter_btn_main {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      button {
        border: 1px solid var(--home-color);
        padding: 0.7rem 0;
        width: 40%;
        border-radius: 5px;

        &:first-child {
          background-color: white;
          color: var(--home-color);
        }

        &:last-child {
          background-color: var(--home-color);
          color: white;
        }
      }
    }

  }

  .close_side_bar_btn {
    position: absolute;
    right: -43px;
    top: 75px;
    width: 43px;
    height: 44px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-left: 0;
    border-radius: 0 4px 4px 0;
    font-size: 1px;
    line-height: 1px;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
  }

  .project_detail_header {
    text-align: right;

    #project_admin_name {
      color: gray;
      margin-left: 0.4rem;
    }

  }

  .project_create_time {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    small {
      color: gray;
    }
  }
}


.project_tab_main {
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
}


.project_work_tab_main {
  padding: 1rem 0;

  .click_polygon_btn_wrapper {
    margin: 0 0.5rem 0.5rem 0.5rem;

    button {
      width: 100%;
      background-color: white;
      padding: 0.4rem 0;
      border-radius: 5px;
      color: var(--home-color);
      border: 1px solid var(--home-color);
    }

    &.active {
      button {
        color: red;
        border-color: red;
      }
    }
  }

  .work_add_btn_main {
    padding: 0.5rem 0;
    margin: 0 0.5rem 0.5rem 0.5rem;

    button {
      width: 46%;
      background-color: white;
      padding: 0.4rem 0;
      border-radius: 5px;

      &:first-child {
        color: green;
        border: 1px solid green;
      }

      &:last-child {
        color: var(--home-color);
        border: 1px solid var(--home-color);
      }
    }
  }

  .project_work_box_main {
    position: relative;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 8px;
    margin: 0 0.5rem 0.8rem 0.5rem;
    border: 3px solid white;
    border-radius: 15px;


    .work_box_menu_main {
      position: absolute;
      right: 0;
      top: 5px;
    }

    &:hover, &.active {
      border-color: var(--home-color);
    }

    .work_info {
      label {
        cursor: pointer;
        display: block;
        font-size: 0.8rem;
        color: gray;
      }

      span {
        font-size: 0.9rem;
      }
    }
  }
}


.project_member_tab_main {
  .add_project_member_btn {
    background-color: white;
    border: 1px solid var(--border-secondary);
    color: var(--home-color);
    padding: 0.3rem 0.4rem;
    border-radius: 5px;
  }

  .project_out_btn {
    background-color: white;
    border: 1px solid var(--border-secondary);
    color: red;
    padding: 0.3rem 0.4rem;
    border-radius: 5px;
  }

  .project_member_box {
    width: 100%;
    border-bottom: 1px solid var(--border-secondary);
    padding: 0.3rem 0;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    .member_info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      svg {
        color: var(--home-color);
        width: 45px;
        height: 45px;
        margin-right: 0.5rem;
      }

      small {
        color: gray;
      }
    }
  }
}

.add_member_dialog_main {
  .member_authority_list {
    span {
      border: 1px solid var(--border-secondary);
      padding: 0.2rem 0.3rem;
      border-radius: 30px;
      background-color: white;
    }
  }
}

.project_setting_tab_main {
  padding: 1rem 0;

  hr {
    margin: 0.5rem 0;
  }

  .project_setting_info {
    cursor: pointer;

    &:hover {
      background-color: var(--border-secondary);
    }

    label {
      display: block;
      color: gray;
      font-size: 0.8rem;
    }

    svg {
      color: gray;
      width: 20px;
      height: 20px;
    }
  }

  .project_setting_btn_main {
    padding: 1rem 0;

    button {
      width: 100%;
      display: block;
      padding: 0.4rem 0;
      margin-bottom: 1rem;

      &:nth-child(2) {
        border: 1px solid green;
        color: green;
        background-color: white;
      }

      &:nth-child(1) {
        border: 1px solid var(--home-color);
        color: var(--home-color);
        background-color: white;
      }

      &:nth-child(3) {
        border: 1px solid red;
        color: red;
        background-color: white;
      }
    }
  }
}


.project_statistics_tab_main {

  .stat_title {
    font-weight: bold;
    color: #3c3b3b;
  }

  .total_stat_box {
    border: 1px solid var(--border-secondary);
    display: flex;

    .total_count, .total_area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      padding: 12px 8px;

      span {
        &:first-child {
          font-weight: bold;
        }
      }
    }

    .total_count {
      border-right: 1px solid var(--border-secondary);
    }
  }

  .project_stat_count_main {
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0;

    .stat_progress_item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      img {
        width: 30px;
        height: 30px;
      }

      .progress_info_bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 0.5rem;


        p {
          margin-bottom: 0;

          span {
            color: gray;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .project_stat_circle_main {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }


  .worker_stat_list_main {
    .stat_list {
      padding: 1rem 0;

      .worker_stat_item {
        cursor: pointer;
        margin: 0 8px 1rem 8px;

        #panel-header {
          &:hover {
            opacity: 0.5;
          }
        }

        #status_color_circle {
          margin-right: 0.3rem;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        .MuiAccordion-root {
          width: 100%;
        }

        svg {
          width: 40px;
          height: 40px;
          color: var(--home-color);
        }
      }
    }
  }
}

.not_exist_project_list, .work_record_not_exist, .not_exist_work_list {
  min-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    color: gray;
    font-weight: bold;
  }
}

.work_log_list_main {
  padding: 1rem 0;


  .work_record_item {
    border-bottom: 1px solid var(--border-secondary);
    padding: 16px 4px;

    .record_status_chip {
      max-width: 100px;
      padding: 0.3rem 0.2rem;
      border-radius: 30px;

      span {
        color: white;
        font-weight: bold;
      }
    }

    .record_date_main {
      small {
        color: gray;
      }
    }

    .work_record_image_list {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 0.5rem;

      img {
        cursor: pointer;
        width: 50px;
        margin-right: 0.5rem;
      }
    }
  }
}


.google_search_bar_wrapper {
  position: relative;
  margin-bottom: 1rem;

  .MuiInputBase-root {
    width: 100%;
  }

  #assign_active_modal_btn {
    background-color: white;
    border: 1px solid red;
    color: red;
    border-radius: 15px;
    padding: 0.3rem 0.6rem;
    font-weight: bold;
  }


  .search_result_wrapper {
    z-index: 9999;
    position: absolute;
    top: 100%;
  }

  .ant-input-search {
    input {
      width: 100%;
    }
  }

  .place_list_wrapper {
    border-radius: 5px;
    list-style: none;
    margin: 0;
    padding: 0;

    &.show_border {
      border: 1px solid #d5d5d5;
    }

    li {
      cursor: pointer;

      &:hover {
        background-color: #d5d5d5;
      }

      h4 {
        margin: 0;
        padding: 1rem;
        font-size: 1rem;
      }
    }
  }

  .ant-list-items {
    list-style: none;
    padding: 0;

    .ant-list-item-meta-content {
      padding: 0.5rem;

      h4 {
        font-size: 1.1rem;
      }
    }
  }
}